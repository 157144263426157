import RelativeTime from '@yaireo/relative-time'

export function parseDate(date) {
  const currentDate = new Date().toISOString();
  if (date > currentDate) {
    date = currentDate;
  }
  const relativeTime = new RelativeTime();
  const dateObj = new Date(date);
  return "Posted " + relativeTime.from(dateObj);
}

export function find_text(arr, text) {
  const ret = arr.find(function (el) {
    return el.includes(text);
  });
  return ret;
}

export function getDomain(url) {
  const split = url.split("/");
  const ret = find_text(split, ".").split(":")[0];
  return ret;
}
