import React from "react";
import {
  MDBCardBody,
  MDBCardFooter,
  MDBCardLink,
  MDBCardSubTitle,
  //MDBCardText,
  MDBCardTitle,
  MDBCard,
} from "mdb-react-ui-kit";
import Enform from "enform";
import "./App.scss";
import { parseDate } from "./utils.js";
import Pagination from "@mui/material/Pagination";

class App extends React.Component {
  state = {
    contentList: {
      errorMessage: "START",
      jobs: { p: 0, j: [] },
    },
    search: "",
    lastSearch: "",
    page: 1,
  };

  renderErrorMessage = (errorMessage) => {
    return <p className="jobs">{errorMessage}</p>;
  };

  renderJob = (content) => {
    return (
      <div key={content} className="jobs">
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{content.title}</MDBCardTitle>
            <MDBCardSubTitle>{content.company}</MDBCardSubTitle>
            <MDBCardSubTitle>{content.location}</MDBCardSubTitle>
            <MDBCardLink
              href={content.link}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Apply now
            </MDBCardLink>
          </MDBCardBody>
          <MDBCardFooter className="text-muted unselectable">
            {parseDate(content.date)}
          </MDBCardFooter>
        </MDBCard>
      </div>
    );
  };

  onPaginationChange = (e, pageNum) => {
    this.setState({ page: pageNum });
    this.postSearch(this.state.lastSearch, pageNum);
  };

  renderPagination = () => {
    const numPages = this.state.contentList.jobs.p;
    if (numPages <= 1) {
      return null;
    }
    return (
      <Pagination
        count={numPages}
        onChange={this.onPaginationChange}
        shape="rounded"
        color="primary"
        page={this.state.page}
      />
    );
  };

  renderJobs = (content) => {
    const jobs = this.state.contentList.jobs;
    let jobsList = jobs;
    if (jobs.constructor !== Array) {
      jobsList = jobs.j;
    }
    if (jobsList.length === 0) {
      let errorMessage = this.state.contentList.errorMessage;
      if (errorMessage === "START") {
        errorMessage = "";
      } else if (errorMessage.length === 0) {
        errorMessage = "No jobs found. Try searching for something else.";
      }
      return this.renderErrorMessage(errorMessage);
    } else {
      return jobsList.map((content) => {
        return this.renderJob(content);
      });
    }
  };

  onSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  postSearch = (searchText, pageNum) => {
    const fetchUrl = "/api/search";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ q: searchText, p: pageNum }),
    };
    fetch(fetchUrl, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        this.setState({ contentList: { errorMessage: "", jobs: data } });
      })
      .catch((error) => {
        this.setState({
          // error.toString()
          contentList: {
            errorMessage: "No jobs found. Try searching again later.",
            jobs: { p: 0, j: [] },
          },
        });
      });
  };

  search = () => {
    const searchText = this.state.search;
    this.setState({ page: 1, lastSearch: searchText });
    if (searchText.length === 0) {
      this.setState({
        contentList: { errorMessage: "START", jobs: { p: 0, j: [] } },
      });
      return;
    }
    this.postSearch(searchText, 1);
  };

  onInputSubmit = (e) => {
    this.search();
  };

  handleInputKeyPress(e) {
    if (e.key === "Enter") {
      this.search();
    }
  }

  render() {
    return (
      <div className="section">
        <main style={{ marginTop: "4rem" }}>
          <div className="container">
            <div>
              <div style={{ color: "#0078d7" }}>
                <center>
                  <h1>Search for Jobs</h1>
                  <br />
                  <br />
                </center>
              </div>
              <div>
                <center>
                  <Enform
                    initial={{ name: "" }}
                    validation={{
                      name: (values) => values.name === "",
                    }}
                  >
                    {({ values, errors, onChange, onSubmit }) => (
                      <div>
                        <input
                          className={errors.name ? "error" : ""}
                          placeholder="software engineer, product, manager, administrative assistant..."
                          type="text"
                          value={values.name}
                          onChange={(e) => {
                            onChange("name", e.target.value);
                            this.onSearchChange(e);
                          }}
                          onKeyUp={this.handleInputKeyPress.bind(this)}
                        />
                        <button onClick={this.onInputSubmit}>Search</button>
                      </div>
                    )}
                  </Enform>
                </center>
              </div>
            </div>
          </div>
        </main>
        <div className="row">{this.renderJobs(this.state.contentList)}</div>
        <div className="pagination">{this.renderPagination()}</div>
      </div>
    );
  }
}

export default App;
